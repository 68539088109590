.text{
   overflow: hidden;
   text-overflow: ellipsis;
   display: -moz-box;
   -moz-box-orient: vertical;
   display: -webkit-box;
   -webkit-line-clamp: 5;
   -webkit-box-orient: vertical;
   line-clamp: 5;
   box-orient: vertical;
   position: relative;
   &::after{
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 30px;
      z-index: 2;
      background:linear-gradient(transparent,#fff);
   }

}